import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { graphql } from 'gatsby'
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import media from '../tools/mediaQueries';
import Layout from '../layouts';
import Hero from '../components/Hero';

import sslImage from '../assets/images/ssl_badge.png';

const Payment = () => (
  <Layout>
  <Grid>
    <Helmet title="STERN & STERN - Payment" />
    <StyledRow>
      <Hero title="make a payment " />
      {/* <Lead xs={12}>
        <h1>Quickly & securely</h1>
        <img src={lockImage} alt="" />
      </Lead> */}
    </StyledRow>
    <StyledRow>
      <Col xs={12} sm={3}>
        <Row>
          <Title>
            <h3>By Check:</h3>
          </Title>
          <Paragraph xs={12}>
            <p>Stern & Stern</p>
          </Paragraph>
          <Paragraph xs={12}>
            <p>3002 Merrick Road.</p>
          </Paragraph>
          <Paragraph xs={12}>
            <p>Bellmore, NY 11710 </p>
          </Paragraph>
        </Row>
        <Row>
          <Title>
            <h3>By Phone:</h3>
          </Title>
          <Paragraph xs={12}>
            <p>(516) 771-0710</p>
          </Paragraph>
        </Row>
        <Row>
          {/* <Title>
            <h3>* = indicates mandatory field</h3>
          </Title> */}
          <Image>
            <img src={sslImage} alt="" />
          </Image>
        </Row>
      </Col>
      <Col xs={12} sm={9}>
        {/* <p>FORM FOR PAYMENT</p>
        <CheckoutForm /> */}
        <Lead xs={12}>
          <h1>Please Contact This Office For Credit Card & Check By Phone Payments</h1>
        </Lead>
      </Col>
    </StyledRow>
  </Grid>
  </Layout>
);

export default Payment;

const StyledRow = styled(Row)`
  background-color: #fff;
  padding-bottom: 30px;

  .margin-top {
    margin-top: 30px;

    ${media.sm`
      margin-top: 0;
    `};
  }
`;

const Lead = styled(Col)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.sm`
    justify-content: center;
    flex-direction: row;
  `};

  h1 {
    line-height: 34px;
    font-size: 22px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
    color: #4b721d;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;

    ${media.sm`
      font-size: 2.125rem;
    `};

    ${media.md`
      font-size: 36px;
    `};
  }

  img {
    max-width: 20%;

    ${media.sm`
      margin-left: 20px;
    `};
  }
`;

const Title = styled(Col)`
  font-family: 'Raleway';
  margin-top: 20px;

  h3 {
    color: #a85701;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }
`;

const Paragraph = styled(Col)`
  margin-bottom: 10px;
  font-family: 'Raleway';

  p {
    color: #4d4d4d;
    display: block;
    font-weight: 500;
    padding: 2px 0;
    font-size: 18px;
    line-height: 32px;
    margin: 0;
  }
`;

const Image = styled.div`
  width: 100%;

  img {
    max-width: 80%;
  }
`;
